import React from "react";

const Checkbox = ({ label, checked, onCheck, onUnCheck, sx, ...rest }) => {
  return (
    <label className={`inline-flex items-center space-x-2 ${sx ? sx : ""}`}>
      <input
        {...rest}
        type="checkbox"
        checked={checked}
        onChange={() => {
          checked ? onUnCheck() : onCheck();
        }}
        className="h-4 w-4 border-gray-300 text-primary shadow-sm focus:border-gray-300 focus:ring-0 focus:ring-offset-0"
      />
      <span className="text-gray-700">{label}</span>
    </label>
  );
};

export default Checkbox;
