import React, { createContext, useState, useEffect, useCallback } from "react";
import { authorize } from "../httpService";

const lsKey = "HABWDAIWJB";
const UserContext = createContext({});

const UserContextProvider = (props) => {
  const [userEmail, setUserEmail] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const item = localStorage.getItem(lsKey);

    if (item) {
      authorize(atob(item))
        .then((res) => res.json())
        .then((json) => {
          setUserEmail(json.email);
          setIsAdmin(json.admin);
        })
        .catch((err) => localStorage.removeItem(lsKey))
        .finally(() => setInitialized(true));
    } else {
      setInitialized(true);
    }
  }, []);

  const onLogin = useCallback((email, jwt, admin) => {
    setUserEmail(email);
    setIsAdmin(admin);
    localStorage.setItem(lsKey, btoa(jwt));
  }, []);

  const logout = () => {
    setUserEmail(null);
    setIsAdmin(false);
    localStorage.removeItem(lsKey);
  };

  const getJwt = () => {
    const value = localStorage.getItem(lsKey);
    return value ? atob(value) : null;
  };

  return (
    <UserContext.Provider
      value={{
        userEmail,
        onLogin,
        isAdmin,
        initialized,
        logout,
        getJwt,
      }}
      {...props}
    />
  );
};

export { UserContextProvider, UserContext };
