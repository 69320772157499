import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { publishMachine } from "../httpService";
import Button from "../components/button";
import { MachineContext } from "../context/MachineContext";

const UnpublishedMachineManagement = () => {
  const navigate = useNavigate();
  const { isAdmin, getJwt, initialized } = useContext(UserContext);
  const { machines, reinitialize } = useContext(MachineContext);

  const unpublishedMachines = machines.filter((machine) => !machine.published);

  useEffect(() => {
    if (initialized && !isAdmin) {
      navigate("/");
    }
  }, [isAdmin, initialized]);

  return (
    <div className="container mx-auto p-8 max-w-7xl">
      <Helmet>
        <title>AS Maskiner - Avpublicerade maskiner</title>
      </Helmet>
      <h2 className="text-3xl font-bold mb-8">Avpublicerade maskiner</h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {unpublishedMachines.map((machine) => (
          <MachinePreview
            machine={machine}
            getJwt={getJwt}
            key={machine.id}
            refresh={reinitialize}
          />
        ))}
      </div>
    </div>
  );
};

const MachinePreview = ({ machine, getJwt, refresh, sx }) => (
  <div className={`bg-white p-4 border ${sx ? sx : ""}`.trim()}>
    <div className={`min-h-[300px]`}>
      <Link
        to={`/machines/${machine.id}`}
        data-testid={`machine-${machine.id}-preview-link`}
      >
        <img
          src={machine.images[0]}
          alt={machine.title}
          className="w-full h-48 object-cover mb-4"
        />
        <h3 className={`text-lg font-bold`}>{machine.title}</h3>
      </Link>
      <p className="text-gray-500 mb-3">{machine.category}</p>
    </div>
    {!!machine.price && (
      <h3 className="text-xl font-semibold mt-2 mb-4">
        {new Intl.NumberFormat("sv-SE", {
          style: "currency",
          currency: machine.currency || "SEK",
        }).format(machine.price)}
      </h3>
    )}
    <Button
      onClick={() => {
        publishMachine({ jwt: getJwt(), id: machine.id })
          .then(() => {
            alert("Machine published");
            refresh();
          })
          .catch((err) => alert("Machine could not be published"));
      }}
    >
      Publicera
    </Button>
    <hr className="my-2 mt-5 mb-5" />
    <h5 className="text-md font-bold mb-2">Kortfattade specifikationer</h5>
    <ul className="text-sm text-gray-500 list-disc ml-4">
      <li>
        Artikelnummer: <strong>#{machine.id}</strong>
      </li>
      <li>
        Skick: <strong>{machine.condition}</strong>
      </li>
      <li>
        Tillgänglig: <strong>Ja</strong>
      </li>
      {machine.buildYear && (
        <li>
          Årsmodell: <strong>{machine.buildYear}</strong>
        </li>
      )}
    </ul>
  </div>
);

export default UnpublishedMachineManagement;
