async function getMachines(jwt) {
  const headers = jwt
    ? {
        Authorization: jwt,
      }
    : {};

  const response = await fetch("/api/machines", {
    method: "GET",
    headers,
  });

  const machines = await response.json();
  return machines;
}

async function createSellProposal(sellProposal) {
  const response = await fetch("/api/sell-proposals", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(sellProposal),
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to create sell proposal"),
    };
  }

  return response;
}

async function contact(message) {
  const response = await fetch("/api/contact", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(message),
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to send message"),
    };
  }

  return response;
}

async function resendOtp(jwt) {
  const response = await fetch("/api/users/otp/resend", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwt,
    },
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to resend otp"),
    };
  }

  return response;
}

async function sendForgotPasswordOtp(email) {
  const response = await fetch("/api/users/otp/forgot-password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
    }),
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to send otp"),
    };
  }

  return response;
}

async function verifyOtp(otp, jwt) {
  const response = await fetch("/api/users/otp/verify", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwt,
    },
    body: JSON.stringify({
      otp: otp,
    }),
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to verify otp"),
    };
  }

  return response;
}

async function verifyForgotPasswordOtp(otp, email, newPassword) {
  const response = await fetch("/api/users/otp/forgot-password/verify", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      otp,
      email,
      newPassword,
    }),
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to verify otp"),
    };
  }

  return response;
}

async function login(loginRequest) {
  const response = await fetch("/api/users/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(loginRequest),
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to login"),
    };
  }

  return response;
}

async function register(registerRequest) {
  const response = await fetch("/api/users/register", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(registerRequest),
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to register"),
    };
  }

  return response;
}

async function authorize(jwt) {
  const response = await fetch("/api/users/authorize", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwt,
    },
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Not authorized"),
    };
  }

  return response;
}

async function createMachine({ formData, jwt }) {
  const response = await fetch("/api/admin/machines", {
    method: "POST",
    headers: {
      Authorization: jwt,
    },
    body: formData,
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to create machine"),
    };
  }

  return response;
}

async function deleteMachine({ id, jwt }) {
  const response = await fetch(`/api/admin/machines/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: jwt,
    },
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to delete machine"),
    };
  }

  return response;
}

async function updateFeatured({ id, featured, jwt }) {
  const response = await fetch(
    `/api/admin/machines/${id}/featured?featured=${featured}`,
    {
      method: "PUT",
      headers: {
        Authorization: jwt,
      },
    }
  );

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to update featured"),
    };
  }

  return response;
}

async function updateMachine({ id, machine, jwt }) {
  const response = await fetch(`/api/admin/machines/${id}`, {
    method: "PUT",
    headers: {
      Authorization: jwt,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(machine),
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to update machine"),
    };
  }

  return response;
}

async function uploadFile({ file, jwt, compress }) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("compress", compress);

  const response = await fetch(`/api/admin/file/upload`, {
    method: "POST",
    headers: {
      Authorization: jwt,
    },
    body: formData,
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to upload file"),
    };
  }

  const json = await response.json();
  return json.url;
}

async function getSellProposals({ jwt }) {
  const response = await fetch(`/api/admin/sell-proposals`, {
    headers: {
      Authorization: jwt,
    },
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to get sell proposals"),
    };
  }

  return await response.json();
}

async function publishMachine({ jwt, id }) {
  const response = await fetch(`/api/admin/machines/${id}/publish`, {
    method: "PUT",
    headers: {
      Authorization: jwt,
    },
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to publish machine"),
    };
  }

  return;
}

async function unpublishMachine({ jwt, id }) {
  const response = await fetch(`/api/admin/machines/${id}/unpublish`, {
    method: "PUT",
    headers: {
      Authorization: jwt,
    },
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to unpublish machine"),
    };
  }

  return;
}

async function addSellProposalEvent({ sellProposalId, description, jwt }) {
  const response = await fetch(`/api/admin/sell-proposals/events`, {
    method: "PUT",
    headers: {
      Authorization: jwt,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      sellProposalId,
      description,
    }),
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to add sell proposal event"),
    };
  }

  return await response.json();
}

export {
  getMachines,
  createSellProposal,
  contact,
  login,
  register,
  authorize,
  createMachine,
  deleteMachine,
  getSellProposals,
  addSellProposalEvent,
  updateFeatured,
  verifyOtp,
  resendOtp,
  sendForgotPasswordOtp,
  verifyForgotPasswordOtp,
  updateMachine,
  publishMachine,
  unpublishMachine,
  uploadFile,
};
