import React from "react";
import Button from "./button";

function ImagesEditor({ images, onChange, uploadImageFn }) {
  const fileInputRef = React.useRef(null);

  const handleAddClick = (compress) => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
      fileInputRef.current.dataset.compress = compress ? "true" : "false";
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const compress = e.target.dataset.compress === "true";

    try {
      const newUrl = await uploadImageFn(file, compress);
      onChange([...images, newUrl]);
    } catch (error) {
      alert("Could not upload image");
      console.error(error);
    } finally {
      delete fileInputRef.current.dataset.compress;
    }
  };

  const removeImage = (index) => {
    const updated = [...images];
    updated.splice(index, 1);
    onChange(updated);
  };

  const moveImageUp = (index) => {
    if (index === 0) return;
    const updated = [...images];
    [updated[index - 1], updated[index]] = [updated[index], updated[index - 1]];
    onChange(updated);
  };

  const moveImageDown = (index) => {
    if (index === images.length - 1) return;
    const updated = [...images];
    [updated[index + 1], updated[index]] = [updated[index], updated[index + 1]];
    onChange(updated);
  };

  return (
    <div className="mb-4">
      <h3 className="text-xl font-bold mb-4">Bilder</h3>
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />

      <p className="text-sm text-gray-600 mb-2">
        Första bilden (överst) används som <strong>primär bild</strong>.
      </p>

      {images.map((imageUrl, index) => (
        <div key={index} className="flex items-center gap-4 mb-4">
          <img
            src={imageUrl}
            alt={`Preview ${index}`}
            className="w-48 h-48 object-cover border"
          />
          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              <Button onClick={() => moveImageUp(index)} disabled={index === 0}>
                ⬆
              </Button>
              <Button
                onClick={() => moveImageDown(index)}
                disabled={index === images.length - 1}
              >
                ⬇
              </Button>
            </div>
            <Button onClick={() => removeImage(index)}>Ta bort</Button>
          </div>
        </div>
      ))}

      <div className="flex gap-4 mt-4">
        <Button onClick={() => handleAddClick(true)}>
          Lägg till ny bild (komprimerad)
        </Button>
        <Button onClick={() => handleAddClick(false)}>
          Lägg till ny bild (okomprimerad)
        </Button>
      </div>
    </div>
  );
}

export default ImagesEditor;
