import React, { useState, useEffect, useContext } from "react";
import logoUrl from "../assets/logo.png";
import routes from "../routes/routes";
import { Link } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import LoginOrRegisterModal from "./loginOrRegisterModal.jsx";
import Button from "./button.jsx";

const Header = () => {
  const [mobileSiderIsActive, setMobileSiderIsActive] = useState(false);
  const [loginModalOpened, setLoginModalOpened] = useState(false);
  const [adminDropdownVisible, setAdminDropdownVisible] = useState(false);
  const { isAdmin, userEmail, logout, onLogin } = useContext(UserContext);

  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    if (mobileSiderIsActive) {
      document.body.classList.add("lock-scroll");
    } else {
      document.body.classList.remove("lock-scroll");
    }

    return () => (document.body.style.overflow = originalStyle);
  }, [mobileSiderIsActive]);

  return (
    <>
      <header className="bg-secondary text-white border-b border-b-[0.1px] border-gray-600 sticky top-0 z-40">
        {userEmail && isAdmin && (
          <div className="absolute p-4">
            <div className="relative">
              <Button onClick={() => setAdminDropdownVisible((prev) => !prev)}>
                Admin
              </Button>
              {adminDropdownVisible && (
                <div className="absolute bg-white shadow-md rounded-md mt-2 w-48 flex flex-col">
                  {[
                    { path: "machines/create", label: "Skapa maskin" },
                    { path: "sell-proposals", label: "Offerthantering" },
                    {
                      path: "machines/unpublished",
                      label: "Avpublicerade maskiner",
                    },
                  ].map((link) => (
                    <Link
                      key={link.path}
                      to={link.path}
                      className="px-4 py-2 text-sm text-primary"
                      onClick={() => setAdminDropdownVisible(false)}
                    >
                      {link.label}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
        <nav className="container mx-auto max-w-7xl flex justify-between items-center py-3 px-4">
          <Link
            to="/"
            className="flex items-center"
            data-testid="as-maskiner-logo-link"
          >
            <img src={logoUrl} alt="AS Maskiner" className="h-10" />
          </Link>

          <button
            className="lg:hidden text-primary focus:outline-none"
            onClick={() => setMobileSiderIsActive((prev) => !prev)} // Toggle the state
          >
            {mobileSiderIsActive ? (
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            )}
          </button>

          <div className="flex-grow hidden lg:flex lg:items-center ml-8">
            {Object.values(routes).map((route, index) => (
              <Link
                key={index}
                to={route.url}
                className="mx-2 lg:mx-4 text-sm lg:text-base hover:text-primary"
              >
                {route.name}
              </Link>
            ))}
          </div>
          <div className="hidden lg:block">
            {userEmail ? (
              <div className="flex flex-col">
                <span className="text-primary">{userEmail}</span>
                <span className="text-xs cursor-pointer" onClick={logout}>
                  Logga ut
                </span>
              </div>
            ) : (
              <Link
                to="#"
                className="hidden lg:block text-white py-2 px-6 border border-primary hover:bg-primary hover:text-white transition-colors duration-300"
                onClick={() => setLoginModalOpened(true)}
              >
                Logga in
              </Link>
            )}
          </div>
        </nav>
      </header>
      {mobileSiderIsActive && (
        <div className="absolute z-50 bg-secondary h-full w-full">
          <div className="p-4">
            {userEmail ? (
              <div className="flex flex-col p-4">
                <span className="text-primary">{userEmail}</span>
                <span
                  className="text-xs cursor-pointer text-white"
                  onClick={logout}
                >
                  Logga ut
                </span>
              </div>
            ) : (
              <Link
                to="#"
                className="block px-4 py-2 text-white"
                onClick={() => setLoginModalOpened(true)}
              >
                Logga in
              </Link>
            )}
            {Object.values(routes).map((route, index) => (
              <Link
                key={index}
                to={route.url}
                className="block px-4 py-2 text-white"
                onClick={() => setMobileSiderIsActive(false)}
              >
                {route.name}
              </Link>
            ))}
          </div>
        </div>
      )}
      {loginModalOpened && (
        <LoginOrRegisterModal
          onClose={() => setLoginModalOpened(false)}
          onCompleted={(res) => {
            onLogin(res.email, res.jwt, res.admin);
            setLoginModalOpened(false);
          }}
        />
      )}
    </>
  );
};

export default Header;
